import { Box } from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import React from "react";
import UiButton from "../components/atoms/UiButton";
import Filterbar from "../components/organisms/Filterbar";
import CardsBox from "../components/templates/CardsBox";
import HeroSection from "../components/templates/HeroSection";
import Layout from "../components/templates/Layout";
import { homePageData } from "../lib/constants";
import { Mongo } from "../util/MongoScript";

function Home({ data }) {
  //new structure
  const artistList = JSON.parse(data?.artistList);
  const serviceTypesList = JSON.parse(data?.serviceTypesList);
  const ethnicArtistsServices = JSON.parse(data?.ethnicArtistsServices);
  const list = artistList.filter((i) => i.businessName === 'Styledbytkl')
  //Rearange element
  const third = serviceTypesList[2];
  const ist = serviceTypesList[0];
  serviceTypesList[0] = third;
  serviceTypesList[2] = ist;
  const orderbyOptions = [
    {
      label: "Price",
      value: "price",
      selected: false,
    },
    // {
    //   label: "Nearest",
    //   value: "nearest",
    //   selected: false,
    // },
    {
      label: "popularity",
      value: "popularity",
      selected: false,
    },
    // {
    //   label: "Last minute appointment",
    //   value: "lastminute",
    //   selected: false,
    // },
  ];
  const showByOptions = [
    {
      label: "Individuals",
      value: "individuals",
      selected: false,
    },
  ];
  const serviceTypeOptions = [];
  serviceTypesList?.map((item, index) => {
    serviceTypeOptions?.push({
      label: item?.name,
      value: item?._id,
      selected: false,
      ...item,
    });
  });
  const removeItem = [
    "60f65f72b35fea22c897f8e5",
    "60f65468b35fea22c897f8e0",
    "60f653e7b35fea22c897f8d9",
    "60f6545bb35fea22c897f8df",
    "60f65429b35fea22c897f8da",
    "60f65474b35fea22c897f8e1",
  ];
  const refactorServiceTypeOptions = [
    ...serviceTypeOptions.filter((i) => !removeItem.includes(i.value)),
    { headingLabel: "Ethnic skills" },
  ];
  ethnicArtistsServices?.map((item, index) => {
    refactorServiceTypeOptions?.push({
      label: item?.name,
      value: item?._id,
      selected: false,
      ...item,
      etnic: true,
    });
  });

  const allOptions = [
    {
      label: "Search",
      options: "",
    },
    {
      label: "Order by",
      options: orderbyOptions,
    },

    {
      label: "Services",
      options: refactorServiceTypeOptions,
    },
  ];
  if (typeof window !== "undefined") {
    const localFilters = localStorage.getItem("filters");
    // var savedFilters = JSON.parse(localFilters) || allOptions;
    var savedFilters = allOptions;
  }

  const [filters, setFilters] = React.useState(savedFilters);
  const [limit, setLimit] = React.useState(8);
  const [selectedServiceTypes, setSelectedServiceTypes] = React.useState(
    filters?.[2]?.options?.filter((item) => item?.selected) || []
  );

  const filterArtists = [];
  selectedServiceTypes.forEach((serviceType) => {
    artistList?.forEach((artist) => {
      const ethnicArtistExist =
        serviceType?.etnic &&
        artist?.ethnicArtistServices?._id === serviceType?._id;
      artist?.servicesList?.forEach((item) => {
        if (
          (item?.serviceTypeId === serviceType?._id || ethnicArtistExist) &&
          !filterArtists.find((item) => item?._id === artist?._id)
        ) {
          filterArtists?.push(artist);
        }
      });
    });
  });
  if (!filterArtists?.length && selectedServiceTypes.length === 0) {
    filterArtists.push(...artistList);
  }

  const clearFilters = () => {
    setFilters(allOptions);
    localStorage.removeItem("filters");
  };
  const applyFilters = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("filters", JSON.stringify(filters));
    }
    setSelectedServiceTypes(
      filters?.[2]?.options?.filter((item) => item?.selected)
    );
  };

  const pageData = homePageData.filter((item) => item?.slug === "home")[0];

  return (
    <Layout>
      <NextSeo
        title={
          pageData?.title ||
          "CoverClap: Find your Perfect Beauty Stylist in Montreal 2022"
        }
        description={
          pageData?.description ||
          "A live curation of Montreal's best stylists every day. Discover the latest hairstylists, colorists, makeup and nail artists... and more"
        }
        openGraph={{
          url: "https://coverclap.com/blog",
          type: "website",
          siteName: "CoverClap",
          images: [
            {
              url: "https://coverclap.com/new-ui/images/coverclap.png",
              width: 1200,
              height: 1076,
            },
          ],
        }}
      />
      <HeroSection data={pageData} />
      <Filterbar
        orderbyOptions={filters?.[1]}
        showByOptions={filters?.[0]}
        serviceTypeOptions={filters?.[2]}
        allOptions={filters}
        setFilters={setFilters}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
      />
      <CardsBox
        serviceTypes={serviceTypesList}
        artists={
          selectedServiceTypes.length === 0
            ? filterArtists
            : filterArtists?.slice(0, limit)
        }
        selectedServiceTypes={selectedServiceTypes}
        homePageData={selectedServiceTypes.length === 0 ? true : false}
        keywordPage={false}
        isHairSalons={false}

      />

      <Box px="20px">
        <Box
          margin="auto"
          marginTop={"-20px"}
          mb={["42px", "157px"]}
          width={["100%", "172px"]}
          height="54px"
          borderRadius={"full"}
        >
          {filterArtists?.length !== 0 ? (
            <UiButton
              onClick={() => {
                limit >= filterArtists?.length
                  ? setLimit(limit)
                  : setLimit(limit + 8);
              }}
              bgColor={"#64E0F5"}
              color={"#000000"}
              hFull
              label={"View More"}
            />
          ) : (
            <Box>Coming soon</Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export default Home;
export async function getStaticProps() {
  let [artistList, serviceTypesList, ethnicArtistsServices] = await Promise.all(
    [
      Mongo.findAllWithPopulate("Artist", { onBoarded: true, isActivated: true }, [
        {
          path: "ethnicArtistServices",
          model: "EthnicArtists",
        },
        {
          path: "servicesList",
          model: "ArtistServices",
          match: {
            name: {
              $gte: " ",
            },
          },
          populate: {
            path: "images",
            model: "Artistimages",
          },
        },
      ]),
      Mongo.findAll("ServiceTypes"),
      Mongo.findAll("EthnicArtists"),
    ]
  );
  const data = {
    // new structure
    artistList: JSON.stringify(artistList),
    serviceTypesList: JSON.stringify(serviceTypesList),
    ethnicArtistsServices: JSON.stringify(ethnicArtistsServices),
  };
  return {
    props: {
      data,
    },
    revalidate: 1,
  };
}
